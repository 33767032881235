import React, { useCallback, ReactElement, useMemo } from 'react';
import Select, { components, SingleValueProps } from 'react-select';
import { Box, Flex } from 'theme-ui';

import { Icon } from '@sprinklr/shared-lib/components/icon';
import { Option } from '../types';

const STYLES = {
  control: base => ({
    ...base,
    borderRadius: '20px',
    width: '320px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const Dropdown = ({
  options,
  icon,
  label,
  selectedOption,
  handleSelect,
}: {
  options: Option[];
  icon: string;
  label: string;
  selectedOption: Option;
  handleSelect: (option: Option) => void;
}): ReactElement => {
  const SingleValueComponent = useMemo(
    () => (props: SingleValueProps<Option>) => {
      return (
        <components.SingleValue {...props}>
          <Flex
            data-type="single-value"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
          >
            <Box sx={{ flex: '0 0 auto' }}>
              <Icon
                iconName={icon}
                iconSx={{
                  width: 40,
                  height: 40,
                  marginRight: '4px',
                  display: 'block',
                }}
              />
            </Box>
            <Box sx={{ flex: '1' }} data-type="label">
              <Box
                sx={{
                  fontSize: ['16px', '20px'],
                  color: 'dropdownLightGrey',
                  marginBottom: '2px',
                }}
              >
                {label}
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: ['16px', '20px'],
                  lineHeight: ['24px', '32px'],
                  color: 'dropdownDarkGrey',
                }}
              >
                {props.data.label}
              </Box>
            </Box>
          </Flex>
        </components.SingleValue>
      );
    },
    [icon, label],
  );

  const onSelect = useCallback(
    option => {
      handleSelect(option);
    },
    [handleSelect],
  );

  return (
    <Select
      isSearchable={false}
      onChange={onSelect}
      components={useMemo(
        () => ({ SingleValue: SingleValueComponent }),
        [SingleValueComponent],
      )}
      placeholder={label}
      options={options}
      value={selectedOption}
      styles={STYLES}
    />
  );
};

export default Dropdown;
