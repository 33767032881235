import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import React, { useState, useMemo, useCallback, ReactElement } from 'react';
import { Flex } from 'theme-ui';

import TemplateRenderer from '../../components/tempateRenderer/TemplateRenderer';
import Dropdown from './components/Dropdown';
import { Option, SelectableTemplateProps } from './types';
import { extractDropdownOptions } from './utils';

const SelectableTemplate = ({
  paddingTop,
  paddingBottom,
  options,
  dropdownTitle,
  dropdownIcon,
}: SelectableTemplateProps): ReactElement => {
  const [pt, pb] = useContainerPadding({
    top: paddingTop || 'SMALL',
    bottom: paddingBottom || 'SMALL',
  });

  const dropdownOptions = useMemo(
    () => extractDropdownOptions(options),
    [options],
  );

  const [selectedOption, setSelectedOption] = useState<Option>(
    dropdownOptions[0],
  );

  const template = useMemo(() => {
    const currentOption = options.find(
      option => option.dropdownId === selectedOption.value,
    );
    return currentOption ? currentOption.template : null;
  }, [options, selectedOption]);

  const onSelect = useCallback(
    (option: Option) => {
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  return (
    <Flex
      sx={{
        pt,
        pb,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Dropdown
        options={dropdownOptions}
        icon={dropdownIcon?.icon}
        label={dropdownTitle}
        selectedOption={selectedOption}
        handleSelect={onSelect}
      />
      {template ? <TemplateRenderer templates={[template]} /> : null}
    </Flex>
  );
};

export default SelectableTemplate;
